<template>
  <div class="layoutContainer">
    <!-- 子路由出口 -->
    <router-view v-if="reload"
                 route />
    <!-- 标签导航栏 -->
    <van-tabbar v-model="active"
                class="layoutTabbar">
      <van-tabbar-item to="/home"
                       :name="0">
        <svg-icon slot="icon"
                  icon-class="workbench"></svg-icon>
        {{ $t('layout.workbench') }}
      </van-tabbar-item>
      <!-- staff -->
      <van-tabbar-item :name="2"
                       v-show="showwf"
                       @click="gReqEvent('/weixinlocal/queryrpt/shengpi.html')">
        <svg-icon slot="icon"
                  icon-class="shenqing"></svg-icon>
        {{ $t('layout.apply') }}
      </van-tabbar-item>
      <van-tabbar-item :name="1"
                       v-show="showhr"
                       @click="gReqEvent('/weixinlocal/queryrpt/queryindex.html')">
        <svg-icon slot="icon"
                  icon-class="qurey"></svg-icon>
        {{ $t('layout.query') }}
      </van-tabbar-item>
      <van-tabbar-item :name="4"
                       to="/report"
                       v-show="reportList.length>0">
        <svg-icon slot="icon"
                  icon-class="report"></svg-icon>
        {{ $t('layout.report') }}
      </van-tabbar-item>
      <!-- statistics -->
      <!-- personCen -->
      <van-tabbar-item :name="3"
                       to="/personCen"
                       icon="manager">
        <!-- <svg-icon slot="icon"
        :icon-class="active == 3 ? 'mine1' : 'personCenter'"></svg-icon>-->
        {{ $t('layout.my') }}
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getReportList } from '@api/wxrp.js'
import { getWxTopBtnList } from '@api/wx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  name: 'layoutContainer',
  provide () {
    return {
      reload: this.reloadFun
    }
  },
  data () {
    return {
      userInfo,
      active: 0,
      reload: true,
      reportList: [],
      V3url:
        //process.env.NODE_ENV === 'production' ? window.V3url :
        './',
      showhr: true,
      showwf: true
    }
  },
  created () {
    getReportList({ username: this.userInfo.username }).then(res => {
      this.reportList = res.data.filter(item => item.autoid != 0)
    })
    getWxTopBtnList({ username: this.userInfo.username }).then(res => {
      if (res.panellist)
      {
        this.showhr = res.panellist[0].showhr
        this.showwf = res.panellist[0].showwf
      }
    })
  },
  methods: {
    toastTips () {
      Toast('敬请期待！')
    },
    // 界面跳转
    jump (u) {
      if (u.indexOf('http') > -1)
      {
        location.href = u
      } else
      {
        location.href = this.V3url + u
      }
    },
    reloadFun () {
      this.reload = false
      this.$nextTick(() => {
        this.reload = true
      })
    },
    gReqEvent (url) {
      this.reload = false
      setTimeout(() => {
        this.reload = true
        this.$router.push({
          path: '/jump',
          query: {
            url: (window.V3url ? window.V3url : '') + url,
            time: new Date().getTime()
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="less">
.layoutContainer {
  height: 100%;
  .layoutTabbar {
    padding: 20px 0 12px 0;
    .van-tabbar-item {
      /deep/.van-tabbar-item__icon {
        .van-icon {
          margin-bottom: 16px !important;
        }
      }

      .svg-icon {
        font-size: 40px;
        margin-bottom: 16px;
      }
      .van-tabbar-item__text {
        font-size: 24px;
      }
    }
  }
}
</style>
